export default [
  { from: "/goto/tasks/:id", to: "/task/:id" },
  {
    from: "/goto/tasks_activities/:id",
    to: "/task/:id",
    search: "?modals.activities=true"
  },
  { from: "/goto/stores/:id", to: "/commercial/places/:id" },
  { from: "/goto/legal_file/:id", to: "/folders/legal_file/:id" },
  { from: "/goto/legalfiles/:id", to: "/folders/legal_file/:id" },
  { from: "/goto/events_coordinations/:id", to: "/event_coordination#:id"},
  { from: "/tasks/:id", to: "/task/:id" },
];
