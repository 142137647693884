import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { AppContext } from "../../../providers/AppProvider";
import {
  Avatar,
  CircularProgress,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "80%",
    maxHeight: "27.188rem",
  },
  margin: {
    margin: theme.spacing(1),
  },
  divinput: {
    display: "none",
  },
  linearProgress: {
    width: "6.875rem",
  },
}));

const Reference = ({ resource, textItems }) => {
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);
  const classes = useStyles();

  const fetchData = async () => {
    try {
      let res = await textItems(resource);
      setItem(res);
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!item)
    return (
      <LinearProgress className={classes.linearProgress} color="secondary" />
    );

  if (error) return <span>error_data</span>;

  return (
    <>
      {item == null ? (
        <CircularProgress color="secondary" />
      ) : (
        <ListItemText primary={item} />
      )}
    </>
  );
};

const SelectUrlItemDialog = (props) => {
  const appContext = useContext(AppContext);
  const { httpClient, token, axios } = appContext;
  const classes = useStyles();
  const [listItems, setListItems] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, sethasNextPage] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [currentController, setCurrentController] = useState();

  const {
    source = "item-dialog",
    onSelect,
    onClose,
    open,
    url,
    label,
    textItems,
    imgItems,
    iconItems,
    imgFromData = null,
    async = false,
    filterItems,
    display_search = false,
    limit = 15,
    disableBackdropClick = false,
  } = props;

  const handleScroll = () => {
    var elmnt = document.getElementById(`confirm-dialog-content-${source}`);
    if (
      elmnt.clientHeight + elmnt.scrollTop > elmnt.scrollHeight - 120 &&
      hasNextPage
    ) {
      if (currentController) {
        currentController.cancel();
        setCurrentController(null);
      }
      fetchMoreListItems();
    }
  };

  const fetchMoreListItems = async () => {
    let signal = axios.CancelToken.source();
    setCurrentController(signal);
    setIsFetching(true);
    let params = filterItems({ page: page, search: search, limit: limit });
    try {
      httpClient.defaults.headers.common["Authorization"] = token;
      const res = await httpClient.get(url, {
        params: params,
        cancelToken: signal.token,
      });
      setListItems([...listItems].concat(res["data"].list));
      sethasNextPage(res["data"].pagination.next);
      setPage(page + 1);
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (currentController) {
      currentController.cancel();
      setCurrentController(null);
    }
    fetchMoreListItems();
  }, [search]);


  useLayoutEffect(() => {
    var elmnt = document.getElementById(`confirm-dialog-content-${source}`);
    if (elmnt) {
      elmnt.addEventListener("scroll", handleScroll);
      return () => elmnt.removeEventListener("scroll", handleScroll);
    }
  });
  const handleEntering = () => {};
  const handletextItems = (resource) => {
    return textItems(resource);
  };
  const onSelectItem = (item) => onSelect(item);
  const onCloseSelect = () => onClose();
  const handleChangeSearch = (event) => {
    setPage(1);
    setListItems([]);
    if (event.target.value != "" && event.target.value != null) {
      setSearch(event.target.value);
    } else setSearch();
  };
  ///TODO REVISAR POR QUE SE RENDERIZA VARIAS VECES!!!!

  return (
    <Dialog
      maxWidth="xs"
      onClose={onCloseSelect}
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      disableBackdropClick={disableBackdropClick}
    >
      <DialogTitle>{label}</DialogTitle>
      {display_search && (
        <TextField
          className={classes.margin}
          id="input-with-icon-textfield"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleChangeSearch}
        />
      )}
      <DialogContent dividers id={`confirm-dialog-content-${source}`}>
        <List>
          {listItems.map((option, index) => (
            <ListItem
              key={`${index}-${source}`}
              button
              onClick={() => onSelectItem(option)}
            >
              {imgFromData && (
                <ListItemAvatar>
                  <Avatar
                    className={classes.avatar}
                    src={option["imgFromData"]}
                  />
                </ListItemAvatar>
              )}
              {imgItems && (
                <ListItemAvatar>
                  <Avatar className={classes.avatar} src={imgItems(option)} />
                </ListItemAvatar>
              )}
              {iconItems && (
                <ListItemAvatar>
                  <Icon color="secondary">{iconItems}</Icon>
                </ListItemAvatar>
              )}
              {async ? (
                <Reference
                  resource={option}
                  textItems={(resource) => handletextItems(resource)}
                />
              ) : (
                <ListItemText primary={textItems(option)} />
              )}
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <div> {isFetching && <LinearProgress />}</div>
    </Dialog>
  );
};

export default SelectUrlItemDialog;
