import {
  Backdrop,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../providers/AppProvider";
import { makeStyles } from "@material-ui/core/styles";
import ApiResources from "../../ApiResources";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default () => {
  const { account, httpClient, sync, auth, setApp, translate } =
    useContext(AppContext);
  const classes = useStyles();
  const [syncProcess, setSyncProcess] = useState(false);
  const [textProcess, setTextProcess] = useState(
    "Cargando configuraciones de cuenta ..."
  );
  useEffect(() => {
    if (sync == false) syncDataAccounts();
  }, [account]);

  const syncDataAccounts = async () => {
    setSyncProcess(true);
    const getUserCountries = httpClient
      .get(ApiResources.country_list)
      .then((value) => {
        setTextProcess(translate("datasync", "lbl_load_countries"));
        setApp("SET_COUNTRIES", value["data"]);
      });
    const getUserPlaces = httpClient
      .get(
        `${ApiResources.places_list.replace(
          ":user_id",
          auth.person_id
        )}?filter[account_id]=${account.id}`
      )
      .then((value) => {
        setTextProcess(translate("datasync", "lbl_load_places"));
        setApp("SET_PLACES", value["data"]);
      });
    const getUserAppConfiguration = httpClient
      .get(ApiResources.app_configuration.replace(":id", account.abbr))
      .then((value) => {
        setTextProcess(translate("datasync", "lbl_load_conf_event"));
        setApp("SET_APP_CONFIGURATION", value["data"]);
      });
    const getAccountTranslate = httpClient
      .get(ApiResources.app_translations.replace(":id", account.abbr))
      .then((value) => {
        setTextProcess(translate("datasync", "lbl_load_translate"));
        setApp("SET_TRANSLATIONS", value["data"]);
      });
    const getViewerConfig = httpClient
      .get(ApiResources.viewer_config).then((value) => {
        setTextProcess(translate("datasync", "lbl_load_translate"));
        const partial_viewer_config = value["data"].viewer_config;
        httpClient.get(ApiResources.viewer_book_config).then((value_books) => {
          setApp("SET_VIEWER_CONFIG", { ...partial_viewer_config, ...value_books["data"].viewer_config });
        });
      });
    const getUserProfile = httpClient
      .get(ApiResources.user_profile.replace(":email", auth.email))
      .then((value) => {
        setTextProcess(translate("datasync", "lbl_load_datauser"));
        setApp("SET_ALLIANCE_USER", value["data"]);
      });

    const getUserModules = httpClient
      .get(
        ApiResources.user_config
          .replace(":email", auth.email)
          .replace(":account", account.abbr)
      )
      .then((value) => {
        setTextProcess(translate("datasync", "lbl_load_datauser"));
        // value.data.modules.home.push({
        //   "icon": "icon-home.svg",
        //   "menu_icon": "icon-home-menu.svg",
        //   "name": "process",
        //   "route": "/",
        //   "tag": "process",
        //   "web_routes": []
        // })
        setApp("SET_HOME_MODULES", value.data.modules);
      });

    Promise.all([
      getUserCountries,
      getUserPlaces,
      getUserAppConfiguration,
      getAccountTranslate,
      getViewerConfig,
      getUserProfile,
      getUserModules
    ]).then(() => {
      setSyncProcess(false);
      setApp("SET_SYNC", true);
    });
  };
  return (
    <>
      {syncProcess && (
        <Backdrop className={classes.backdrop} open={syncProcess}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {textProcess}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CircularProgress color="inherit" />
            </Grid>
          </Grid>
        </Backdrop>
      )}
    </>
  );
};
