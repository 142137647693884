export default {
  //Modules
  events_list: "/api/v2/me/:user_id/events",
  events_viewer_list: "/api/v2/events",
  events_create: "/api/v2/events",
  events_update: "/api/v2/events/:id",
  events_evidences: "/api/v2/events/:id/evidences",
  event_evidence: "/api/v2/evidences/:id",
  event_description_patch: "/api/v2/events/:id/event_description",
  event_related_people: "/api/v2/events/:id/related_people",
  add_people: "/api/v2/people",
  event: "/api/v2/events/:id",
  event_logs: "/api/v2/events/:id/logs",
  event_product_losses: "/api/v2/events/:id/losses",
  losses_update: "/api/v2/losses/:id",
  account_products: "/api/v2/accounts/:account_id/products",
  additional_data: "/api/v2",
  tasks_list: "/api/v2/me/:user_id/tasks",
  tasks_item: "/api/v2/tasks/:id",
  tasks_item_tag: "/api/v2/tasks/:id/tags",
  tasks_requests_list: "/api/v2/tasks_requests",
  tasks_requests_item: "/api/v2/tasks_requests/:id",
  tasks_requests_item_task: "/api/v2/tasks_requests/:id/tasks",
  accounts_list: "/api/v2/me/:user_id/accounts",
  todolist_item: "/api/v2/todolist/:id",
  todolist_patch_item: "/api/v2/todolist/:id/todos",
  todolist_add_comments: "/api/v2/todolist/:id/comments",
  todolist_add_evidences: "/api/v2/todolist/:id/evidences",
  checklist_item: "/api/v2/checklists/interviews/:id",
  place_item: "/api/v2/stores/:id",
  user_profile: "/api/v2/users/:email/profile",
  user: "/api/v2/users/:email",
  task_score: "/api/v2/task_score",
  task_comment: "/api/v2/tasks/:id/logs",
  task_process: "/api/v2/task_process/:id/assigned",
  task_process_item: "/api/v2/task_process/:id",
  //  En el caso de files llega  /files/id
  files_create: "/api/v2/private/files",
  files_base: "/api/v2",
  todo_item_resource: "/api/v2",
  people_assign: "/api/v2:entity.task/team",
  api_teams: "/api/v2/teams/:id",
  people_places: "/api/v2/people/:id/places",
  parametric_tags: "/api/v2/parametric/tags",
  base_url: "/api/v2",
  tasks: "/api/v2/tasks",
  //Auth
  login: "/api/v2/authenticate",
  reset_password: "/auth/simple/reset_password",
  confirm_reset: "/auth/simple/confirm_reset_password",
  change_password: "/auth/simple/change_password",
  //DataSync
  country_list: "/api/v2/parametric/countries/list",
  geography: "/api/v2/parametric/countries/:country_id/modules/geography",
  industries: "/api/v2/parametric/countries/:country_id/modules/industries",
  places_list: "/api/v2/me/:user_id/places",
  app_configuration: "/api/v2/parametric/accounts/:id/app_configuration",
  app_translations: "/api/v2/parametric/accounts/:id/translations",
  app_country_translations: "/api/v2/parametric/country/:id/translations",
  people: "/api/v2/people/:id",
  place: "/api/v2/stores/:id",
  all_people: "/api/v2/countries/:country_id/people",
  //Overview//
  jurisdictions: "/api/v1-bi/jurisdictions",
  industries: "api/v1-bi/industries",
  comparer: "/api/v2/countries/5/parameters/223",
  states: "/api/v1-bi/people/states?country_id=:country_id",
  // Accounts
  account_logo: "/api/static/logos",
  account_description: "/api/v2/accounts/:id",
  account_places: "/api/v2/accounts/:id/stores",
  account_places_admin: "/api/v2/admin/accounts/:id/stores",
  accounts: "/api/v2/me/:user_id/accounts",
  //callcenter
  // Cambiar a url por pais
  callcenter_events: "/api/v2/countries/:country/events",
  //"responsible_list": "/api/v2/countries/:country_code/people"
  responsible_list: "/api/v2/people",
  //terminos y condiciones
  terminos: "/api/v2/terms",
  verify_terms: "/api/v2/verify",
  save_terms: "/api/v2/save-terms-conditions",
  //taskwizard
  teams_user: "/api/v2/me/:user_id/teams",
  teams_people: "/api/v2/teams/:team_id/users",
  account_people: "/api/v2/accounts/:account_id/people",
  template_checklist: "/api/v2/checklists/templates",
  template_account_checklist: "/api/v2/accounts/:account/checklists/templates",
  template_todolist: "/api/v2/todolist/templates",
  template_account_todolist: "/api/v2/accounts/:account/todolist/templates",
  task_composers: "/api/v2/task_composers",
  task_post_checklist: "/api/v2/checklists/interviews",
  task_post_todolist: "/api/v2/todolist",
  //event_viewer
  viewer_config: "/api/v2/viewer_config",
  viewer_book_config: "/api/v2/viewer_books_config",
  event_viewer: "/api/v2/viewer/:collection",
  search_engine: "/api/v2/search_engine/:collection",
  event_viewer_facet: "/api/v2/viewer/:collection/facets/:facet",
  event_viewer_chart: "/api/v2/viewer/:collection/charts/:chart_type",
  scope_event_viewer: "/api/v2/:scope/:scope_param/viewer/:collection",
  scope_search_engine: "/api/v2/:scope/:scope_param/search_engine/:collection",
  scope_event_viewer_facet: "/api/v2/:scope/:scope_param/viewer/:collection/facets/:facet",
  scope_event_viewer_chart: "/api/v2/:scope/:scope_param/viewer/:collection/charts/:chart_type",
  resource_permissions_list: "/api/v2/resource_permissions",
  viewer_search: "/api/v2/searches",
  viewer_search_list: "/api/v2/me/:user_id/searches",
  viewer_search_item: "/api/v2/searches/:id",
  //overview task
  todo_task: "/api/v2/multi_task/todo",
  compliance_task: "/api/v2/multi_task/performance",
  workload_task: "/api/v2/multi_task/workload",
  kpi_task: "/api/v2/tasks/kpi",
  //event_coordination
  event_coordination_list: "/api/v2/countries/:id/events_coordinations",
  event_coordination_item: "/api/v2/events_coordinations/:id",
  coordination_config:
    "/api/v2/parametric/countries/:country/modules/event_coordination",
  event_coordination_legal: "/api/v2/events_coordinations/:id/legalfiles",
  //userConfig
  user_config: "/api/v2/users/:email/accounts/:account/modules",
  list_users: "/api/v2/users",
  //Folder report abogados
  account_report: "/api/v2/countries/:country/accounts",
  legalfiles_list: "/api/v2/countries/:country/legalfiles",
  folders_config:
    "/api/v2/parametric/countries/:country/modules/folders_config",
  //admin
  users_admin: "/api/v2/admin/users",
  accounts_all: "/api/v2/admin/accounts",
  account_users: "/api/v2/admin/accounts/:account_id/users",
  untie_bind_account_users: "/api/v2/admin/accounts/:account_id/users/:id",
  change_password_users: "/api/v2/admin/users/:email.need_to_change_password",
  unlock_block_users: "/api/v2/admin/users/:email.block",
  user_accounts: "/api/v2/admin/users/:user_id/accounts",
  user_places: "/api/v2/admin/people/:user_id/places",
  place_users: "/api/v2/admin/places/:place_id/users",
  place_users_item:"/api/v2/admin/places/:place_id/users/:id",
  admin_place_item: "/api/v2/admin/stores/:id",
  places_all: "/api/v2/admin/stores",
  //Legal file
  legal_file_item: "/api/v2/legalfiles/:id",
  people_lawyer: "/api/v2/countries/:country/people",
  offenders: "/api/v2/legalfiles/:id/offenders",
  offenders_item: "/api/v2/legalfiles/:legal_file_id/offenders/:id",
  //Books
  books_item: "/api/v2/books/:id",
  me_books: "/api/v2/me/books",
  book_records: "/api/v2/books/:book_id/records",
  books_account: "/api/v2/accounts/:account/books",
  books_assigned: "/api/v2/books/:book_id/assigned",
  record_item: "/api/v2/records/:record_id",
  records_account: "/api/v2/accounts/:account/book_records",
  //Activity
  activity_list: "/api/v2/:owner/:id/activities",
  activity_item: "/api/v2/:owner/:id/activities/:activity_id",
  activity_summary: "/api/v2/:owner/:id/activities.summary",
  activity_task: "/api/v2/tasks/:id/activities",
  // Commercial place
  commercial_list: "/api/v2/countries/:id/places",
  //Config Task
  task_config: "/api/v2/parametric/countries/:country/modules/task_config",
  //Comercial Place Config
  commercial_place_config:
    "/api/v2/parametric/countries/:country/modules/commercial_place_config",
  signal_list: "/api/v2/signals",
  signal_item: "/api/v2/signals/:signal_id",
  //surveys Manager
  template_item: "/api/v2/surveys/templates/:id",
  templates_account: "/api/v2/accounts/:account/surveys/templates",
  template_questions: "/api/v2/surveys/templates/:template_id/questions",
  question_item: "/api/v2/surveys/questions/:question_id",
  questions_account: "/api/v2/accounts/:account/surveys/questions",
  check_task_process: "/api/v2/task_process",
};

