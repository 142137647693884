import { lazy } from 'react';

const Events = lazy(() => import('./modules/Events'));
const Event = lazy(() => import('./modules/Event'));
const TodoList = lazy(() => import('./modules/TodoList'));
const TodoListItem = lazy(() => import('./modules/TodoListItem'));
const Workspace = lazy(() => import('./modules/Workspace'));
const Checklist = lazy(() => import('./modules/Checklist'));
const Views = lazy(() => import('./modules/EventViewer/components/Views'));
const Discovery = lazy(() => import('./modules/Discovery'));
const EventViewer = lazy(() => import('./modules/EventViewer'));
const Overview = lazy(() => import('./modules/Overview'));
const OverviewTask = lazy(() => import('./modules/OverviewTask'));
const InformeTermino = lazy(() => import('./modules/InformeTermino'));
const Tasks = lazy(() => import('./modules/Tasks'));
const Task = lazy(() => import('./modules/Task'));
const Books = lazy(() => import('./modules/Books'));
const BooksList = lazy(() => import('./modules/Books/components/Books'));
const Records = lazy(() => import('./modules/Books/components/Records'));
const Home = lazy(() => import('./modules/Home'));
const PushTask = lazy(() => import('./modules/PushTask'));
const TaskRequest = lazy(() => import('./modules/TaskRequest'));
const TasksRequests = lazy(() => import('./modules/TasksRequests'));
const BooksCustomerList = lazy(() =>
  import('./modules/BooksCustomer/components/Books')
);
const RecordsCustomer = lazy(() =>
  import('./modules/BooksCustomer/components/Records')
);

const Surveys = lazy(() => import("./modules/Surveys"));
const Questions = lazy(() => import("./modules/Surveys/components/Questions"));
const Templates = lazy(() => import("./modules/Surveys/components/Templates"));

export default [
  { public: false, route: '/home', component: <Home />, initial_path: '/home' },
  {
    public: false,
    route: '/workspace',
    component: <Workspace />,
    initial_path: '/workspace',
  },
  { public: false, route: '/', component: <Workspace />, initial_path: '/' },
  {
    public: false,
    route: '/events',
    component: <Events />,
    initial_path: '/workspace',
  },
  {
    public: false,
    route: '/event/:id',
    component: <Event />,
    initial_path: '/events',
  },
  {
    public: false,
    route: '/checklist/:id',
    component: <Checklist />,
    initial_path: '/tasks',
  },
  {
    public: false,
    route: '/todolist/:id',
    component: <TodoList />,
    initial_path: '/tasks',
  },
  {
    public: false,
    route: '/todolist/:id/details/:todo_item',
    component: <TodoListItem />,
    initial_path: '/tasks',
  },
  {
    public: false,
    route: '/tasks',
    component: <Tasks />,
    initial_path: '/workspace',
  },
  {
    public: false,
    route: '/task/:id',
    component: <Task />,
    initial_path: '/tasks',
  },
  {
    public: false,
    route: '/tasks_requests',
    component: <TasksRequests />,
    initial_path: '/tasks',
  },
  {
    public: false,
    route: '/tasks_requests',
    component: <TasksRequests />,
    initial_path: '/tasks',
  },
  {
    public: false,
    route: '/tasks_requests',
    component: <TasksRequests />,
    initial_path: '/tasks',
  },
  {
    public: false,
    route: '/task_request/:id',
    component: <TaskRequest />,
    initial_path: '/tasks',
  },
  { public: false, route: '/views', component: <Views />, initial_path: '' },
  {
    public: false,
    route: '/view/:searchId?',
    component: <EventViewer />,
    initial_path: '/views#list',
  },
  {
    public: false,
    route: '/overview/events',
    component: <Overview />,
    initial_path: '/overview/events',
  },
  {
    public: false,
    route: '/overview/task',
    component: <OverviewTask />,
    initial_path: '/overview/task',
  },
  {
    public: false,
    route: '/overview/discovery',
    component: <Discovery />,
    initial_path: '/overview/discovery',
  },
  {
    public: false,
    route: '/informetermino/:id',
    component: <InformeTermino />,
    initial_path: '/folders',
  },
  {
    public: false,
    route: '/push_tasks/:id',
    component: <PushTask />,
    initial_path: '/tasks',
  },
  {
    public: false,
    route: '/books',
    component: <Books />,
    initial_path: '/books',
  },
  {
    public: false,
    route: '/customer_books/list',
    component: <BooksCustomerList />,
    initial_path: '/workspace',
  },
  {
    public: false,
    route: '/customer_books/:bookID?/records',
    component: <RecordsCustomer />,
    initial_path: '/customer_books/list',
  },
  {
    public: false,
    route: "/surveys",
    component: <Surveys />,
    initial_path: "/surveys",
  },
  {
    public: false,
    route: "/surveys/templates/list",
    component: <Templates />,
    initial_path: "/surveys",
  },
  {
    public: false,
    route: "/surveys/templates/:templateID?/questions",
    component: <Questions />,
    initial_path: "/surveys",
  },
  {
    public: false,
    route: "/surveys/questions/list",
    component: <Questions />,
    initial_path: "/surveys",
  },
];
