import { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Menu, AppBar, Toolbar, Typography, Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import Accounts from "../../Accounts";
import ApiResources from "../../../ApiResources";
import { AppContext } from "../../../providers/AppProvider";
import Translate from "../../Translate";
import CustomMenuItem from "./CustomMenuItem";
import { ProcessesBoxContext } from "../../ProcessesBox/provider";

const useStyles = makeStyles((theme) => ({
  imageLogin: {
    maxWidth: "10.875rem",
    Height: "3.125rem",
    marginTop: "0.313rem",
  },
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  mainAppBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuPaper: {
    width: 220,
  },
}));

const { REACT_APP_CONTEXT: contextApp } = process.env;

export default ({ routeSelected }) => {
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [viewAccounts, setViewAccounts] = useState(false);
  const [viewTranslate, setViewTranslate] = useState(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const {
    translate,
    setApp,
    selected_module,
    current_module,
    account,
    language,
    alliance_user,
    home_modules,
  } = useContext(AppContext);

  const { openProccess } = useContext(ProcessesBoxContext);

  const modules =
    selected_module == "backoffice"
      ? home_modules?.backoffice
      : home_modules?.home;



  const history = useHistory();

  const handleMobileMenuOpen = (event) =>
    setMobileMoreAnchorEl(event.currentTarget);

  const handleChangeExit = () =>
    window.location.assign(`${contextApp}/end_session`);

  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

  const handleChangeAccount = () => {
    setViewAccounts(true);
    handleMobileMenuClose();
  };
  const handleHomeIcon = () => {
    setApp("SET_SELECTED_MODULE", "home");
    history.push(`${contextApp}/workspace`);
    handleMobileMenuClose();
  };

  const handleChangeLanguage = () => {
    setViewTranslate(true);
    handleMobileMenuClose();
  };

  const handleProcess = () => openProccess();

  const handleSwitch = () => {
    handleMobileMenuClose();
    const type = selected_module == "backoffice" ? "home" : "backoffice";
    setApp("SET_SELECTED_MODULE", type);
    history.push(`${contextApp}/workspace`);
    handleMobileMenuClose();
  };

  const renderMobileMenu = () => (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id="primary-search-account-menu-mobile"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      classes={{ paper: classes.menuPaper }}
    >
      {modules?.map((module) => (
        <div key={`menu_${module.name}`}>
          <CustomMenuItem
            srcName={module?.menu_icon}
            label={translate("home", module?.name)}
            clickHandler={() => {
              setApp("SET_CURRENT_MODULE", module);
              history.push(`${contextApp}${module.route}`);
              handleMobileMenuClose();
            }}
          />
        </div>
      ))}
      <Divider
        variant="middle"
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
      />
      <div key={`menu_account`}>
        <CustomMenuItem
          srcName={`${ApiResources.account_logo}/${account?.abbr}_pdf.png`}
          label={account?.name}
          clickHandler={handleChangeAccount}
          fromApi
        />
      </div>
      <div key={`menu_user`}>
        <CustomMenuItem
          srcName="account_circle"
          label={alliance_user?.email}
          isIcon
          noHover
        />
      </div>
      <div key={`menu_process`}>
        <CustomMenuItem
          srcName="sync"
          label={translate("generals", "lbl_process")}
          clickHandler={handleProcess}
          isIcon
          noHover
        />
      </div>
      <div key={`menu_lenguage`}>
        <CustomMenuItem
          srcName="language"
          label={`${language?.name}`}
          clickHandler={handleChangeLanguage}
          isIcon
        />
      </div>
      <div key={`menu_logout`}>
        <CustomMenuItem
          srcName="logout"
          label={translate("generals", "lbl_exit")}
          isIcon
          clickHandler={handleChangeExit}
        />
      </div>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.mainAppBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <div onClick={handleHomeIcon} style={{ color: "inherit" }}>
              <img
                src={`${contextApp}/static/alliance_white.svg`}
                alt="imglogin"
                className={classes.imageLogin}
              />
            </div>
          </Typography>
          <div onClick={handleMobileMenuOpen}>
            <img
              style={{ width: "2.25rem" }}
              src={`${contextApp}/static/icons/home/${routeSelected?.menu_icon.replace(
                ".svg",
                "-selected.svg"
              )}`}
              alt="show-more"
            />
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu()}
      {viewAccounts && (
        <Accounts
          openDialog={viewAccounts}
          handleStatusDialogAccount={(value) => setViewAccounts(value)}
        />
      )}
      {viewTranslate && (
        <Translate
          openDialog={viewTranslate}
          handleStatusDialogTranslate={(value) => setViewTranslate(value)}
        />
      )}
    </div>
  );
};
