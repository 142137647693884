import NavBar from "./NavBar";
import AppRouting from "../../../AppRouting";
import { useContext, useState } from "react";
import { AppContext } from "../../../providers/AppProvider";
import { ProcessesBoxProvider } from "../../ProcessesBox/provider";
import ProcessesBox from "../../ProcessesBox";

const LayoutContainer = () => {
  const { display_menu, getHttpClient, translate } = useContext(AppContext);
  const [routeSelected, setRouteSelected] = useState(null);
  return (
    <ProcessesBoxProvider httpClient={getHttpClient()} translate={translate}>
      {display_menu && <NavBar routeSelected={routeSelected} />}
      <AppRouting setRouteSelected={setRouteSelected} />
      <ProcessesBox />

    </ProcessesBoxProvider>
  );
};

export default LayoutContainer;
