import {
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Typography,
  Snackbar,
  List,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useContext, useEffect } from "react";
import ProcessItem from "./components/processItem";
import CloseIcon from "@material-ui/icons/Close";
import { ProcessesBoxContext } from "./provider";
import NoItems from "../Generals/components/NoItems";

const useStyles = makeStyles((theme) => ({
  headerList: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "0.563rem",
  },
  processIcon: {
    marginRight: theme.spacing(1),
    color: "#FFF",
  },
  actions: {
    textAlign: "center",
    padding: 10,
  },
}));

export default () => {
  const {
    processes = [],
    isOpen,
    process,
    getProcesses,
    removeProcess,
    updateProcess,
    closeViewProcesses,
    translate,
  } = useContext(ProcessesBoxContext);
  const classes = useStyles();

  useEffect(() => {
    getProcesses();
  }, []);
  const handledDeleteProcess = (item) => {
    removeProcess(item);
  };
  if (!isOpen) {
    return <></>;
  }

  return (
    <Snackbar
      open={true}
      onClose={() => null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Card>
        <CardHeader
          className={classes.headerList}
          title={
            <Typography style={{ paddingLeft: "0.625rem" }}>
              {translate("generals", "lbl_process")}
            </Typography>
          }
          action={
            <IconButton
              aria-label="settings"
              className={classes.processIcon}
              onClick={() => closeViewProcesses()}
            >
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent style={{ maxHeight: 200, overflow: "auto" }}>
          <List>
            {processes.map((item, index) => (
              <ProcessItem
                item={item}
                key={item.id}
                index={index}
                handledDeleteProcess={handledDeleteProcess}
                updateProcess={updateProcess}
              />
            ))}
            {processes.length == 0 && <NoItems />}
          </List>
        </CardContent>
      </Card>
    </Snackbar>
  );
};
