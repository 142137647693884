import {
  IconButton,
  List,
  makeStyles,
  Snackbar,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Notifications from "./Notifications";
import { onMessageListener } from "./firebaseInit";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    minWidth: "36ch",
    backgroundColor: "#143d6d",
    color: "#FFFF",
    borderRadius: "10px",
    "& .MuiListItemSecondaryAction-root": {
      top: "15%",
      right: "16px",
      Position: "absolute",
      Transform: "translateY(-50%)",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
    "& .MuiTypography-body1": {
      fontWeight: "bold",
    },
  },
  inline: {
    fontWeight: "bold",
  },
}));

export default () => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({
    title: "",
    body: "",
    image: null,
    messageId: "",
  });
  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        image: payload.notification.image,
      });
    })
    .catch((err) => console.log("failed: ", err));
  return (
    <>
      <Snackbar
        key={notification ? notification.messageId : undefined}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={show}
        onClose={() => setShow(false)}
        autoHideDuration={5000}
        message={notification ? notification.body : undefined}
        action={
          <>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setShow(false)}
            >
              <CloseIcon />
            </IconButton>
          </>
        }
      >
        <List className={classes.root}>
          <ListItem alignItems="flex-start">
            {notification.image && (
              <ListItemAvatar>
                <Avatar src={notification.image} />
              </ListItemAvatar>
            )}

            <ListItemText
              className={classes.inline}
              primary={notification.title}
              secondary={
                <>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    style={{ color: "#FFF" }}
                    noWrap
                  >
                    {notification.body}
                  </Typography>
                </>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                style={{ color: "#FFF" }}
                onClick={() => setShow(false)}
              >
                <CloseIcon style={{ color: "#FFF" }} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Snackbar>
      <Notifications />
    </>
  );
};
