import { useContext, useEffect } from "react";
import { AppContext } from "../../providers/AppProvider";
import SelectUrlItemDialog from "../Generals/components/SelectUrlItemDialog";
import ApiResources from "../../ApiResources";
import ScreenLoader from "../Generals/components/ScreenLoader";

export default ({
  openDialog,
  handleStatusDialogAccount,
  disableBackdropClick = false,
  displaySearch = false
}) => {
  const appContext = useContext(AppContext);
  const { auth, setApp, translate, httpClient, token, accounts, language } =
    appContext;
  const onSelect = (account) => {
    handleStatusDialogAccount(!openDialog);
    setApp("SET_ACCOUNT", account);
  };
  const onClose = () => {
    handleStatusDialogAccount(!openDialog);
  };

  const getDataAccount = async () => {
    // TODO: esta funcion esta mal implementada deberia ir 
    // al detalle de la cuenta y no al listado de cuentas
    httpClient.defaults.headers.common["Authorization"] = token;
    let response = await httpClient.get(
      `${ApiResources.accounts_list.replace(
        ":user_id",
        auth
          ? auth.person_id
          : JSON.parse(localStorage.getItem("auth")).person_id
      )}?filter[id]=${accounts[0].account_id}`
    );
    setApp("SET_ACCOUNT", response["data"].list[0]);
  };
  useEffect(() => {
    if (accounts?.length == 1) getDataAccount();
  }, []);

  if (accounts?.length == 1) return <></>;

  if (!language) return <ScreenLoader />;

  return (
    <SelectUrlItemDialog
      disableBackdropClick={disableBackdropClick}
      open={openDialog}
      onClose={onClose}
      onSelect={onSelect}
      display_search={displaySearch}
      label={translate("accounts", "lbl_title_accounts")}
      url={ApiResources.accounts_list.replace(
        ":user_id",
        auth
          ? auth.person_id
          : JSON.parse(localStorage.getItem("auth")).person_id
      )}
      textItems={(item) => {
        return item.name;
      }}
      imgItems={(item) => {
        return `${ApiResources.account_logo}/${item.abbr}_pdf.png`;
      }}
      filterItems={(item) => {
        let params = {};
        params["filter[name]"] = item.search;
        params["page[number]"] = item.page;
        return params;
      }}
    />
  );
};
