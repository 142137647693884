import { getBrowserToken } from "./firebaseInit";
import { useState, useEffect, useContext } from "react";
import { AppContext } from "../../providers/AppProvider";
const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);
  const { setApp } = useContext(AppContext);
  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getBrowserToken(setTokenFound);
      setApp("SET_TOKEN_PUSH", data);
      return data;
    }
    tokenFunc();
  }, [setTokenFound]);
  return <></>;
};
export default Notifications;
