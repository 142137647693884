import { useState } from "react";
import { MenuItem, Icon, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const { REACT_APP_CONTEXT: contextApp } = process.env;

const useStyles = makeStyles((theme) => ({
  menuItem: {
    "&:hover": {
      backgroundColor: "white",
      paddingLeft: "1rem",
      fontSize: 12,
      fontWeight: "bold",
      color: "#EA4B42",
      "& img": {
        boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.2)",
      },
    },
    "& img": {
      width: "2.25rem",
      borderRadius: "1.5rem",
    },
  },
  menuItemNoHover: {
    "&:hover": {
      backgroundColor: "white",
      paddingLeft: "1rem",
      fontSize: 12,
      fontWeight: "bold",
      color: "#EA4B42",
    },
    "& img": {
      width: "2.25rem",
      borderRadius: "1.5rem",
    },
  },
  accessLabel: {
    paddingLeft: "1rem",
    fontSize: 11,
    fontWeight: "bold",
    color: "#202020",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

export default ({
  srcName,
  label,
  isIcon = false,
  noHover = false,
  fromApi = false,
  clickHandler = () => null,
}) => {
  const classes = useStyles();
  const [imgSrc, setImgSrc] = useState(srcName);
  const [iconColor, setIconColor] = useState("primary");

  return (
    <MenuItem
      onClick={clickHandler}
      classes={{ root: noHover ? classes.menuItemNoHover : classes.menuItem }}
      onMouseEnter={() => {
        if (!noHover) setImgSrc(imgSrc.replace(".svg", "-selected.svg"));
        setIconColor("#EA4B42");
      }}
      onMouseLeave={() => {
        if (!noHover) setImgSrc(srcName.replace("-selected.svg", ".svg"));
        setIconColor("#013D6D");
      }}
    >
      {!isIcon ? (
        <img
          src={fromApi ? imgSrc : `${contextApp}/static/icons/home/${imgSrc}`}
          alt={label}
        />
      ) : (
        <Icon style={{ color: iconColor }}>{srcName}</Icon>
      )}
      {label.includes("@") ? (
        <Tooltip
          title={label}
          arrow
          placement="top-start"
          //classes={{ root: classes.tooltip }}
        >
          <p className={classes.accessLabel}>{label}</p>
        </Tooltip>
      ) : (
        <p className={classes.accessLabel}>{label}</p>
      )}
    </MenuItem>
  );
};
