export default {
	es: {
		login: {
			required_field: "Este campo es obligatorio",
			user_field: "Usuario o email",
			password_field: "Contraseña",
			submit_button: "Iniciar sesión",
			forgot_password: "Olvido su contraseña",
			other_auth_methods: "Conectarse con otro metodo de autenticación",
			go_to: "Ingresar",
		},
		reset_password: {
			required_field: "Este campo es obligatorio",
			user_field: "Usuario o email",
			reset_btn: "Recuperar contraseña",
		},
		sso: {
			loading_auth_progress: "Cargando proceso de autenticación ...",
			go_to_login: "Volver",
			error_sso: "Su tienda no forma parte de este programa o no tiene permiso para acceder a la aplicación. Comuníquese con su lider o con su agente de servicio al cliente."
		},
		otp: {
			required_field: "Este campo es obligatorio",
			user_field: "Usuario o email",
			code_field: "Código",
			confirm_btn: "Confirmar código",
		},
		validate_recovery: {
			required_field: "Este campo es obligatorio",
			user_field: "Usuario o email",
			password: "Contraseña",
			confirm_password: "Confirmar contraseña",
			password_confirm_error: "Las contraseñas no coinciden",
			confirm_btn: "Cambiar contraseña",
			'requirement_1': 'Mínimo 10 caracteres - máximo 40',
      'requirement_2': 'Debe contar al menos una letra en mayúscula',
      'requirement_3': 'Debe contar al menos con un número',
      'msj_pass_change_success' : 'El cambio de contraseña fue exitoso',
      'min_len_character' : 'La contraseña debe contener minimo 10 caracteres',
      'max_len_character' : 'La contraseña debe ser máximo 40 caracteres',
      'blank_spaces' : 'La contraseña no debe tener espacios en blanco',
      'password_lowercase_min' : 'La contraseña debe tener minimo una minúscula',
      'password_uppercase_min' : 'La contraseña debe tener minimo una mayuscula',
      'password_digits_min' : 'La contraseña debe tener minimo un número',
      'password_special_min' : 'La contraseña debe tener mínimo un caracter especial',
      'password_strength' : 'La contraseña no debe ser simple',
      'error_recovery_pass' : 'No se realizo el cambio de contraseña, intente de nuevo'
		},
		logout: {
			title: "Estás apunto de salir",
			subtitle: "¿Estás seguro que deseas salir?",
			confirm: "Si",
			cancel: "No"
		},
		workspaces: {
			required_field: "Este campo es obligatorio",
			btn_workspaces: "Conectarse",
			workspace_field: "Espacio de trabajo"
		}
	},
	en: {
		login: {
			required_field: "This field is required",
			user_field: "User or email",
			password_field: "Password",
			submit_button: "Login",
			forgot_password: "Forgot your password",
			other_auth_methods: "Connect with another authentication method",
			go_to: "Go",
		},
		reset_password: {
			required_field: "This field is required",
			user_field: "User or email",
			reset_btn: "Recover password",
		},
		sso: {
			loading_auth_progress: "Loading authentication process ..",
			go_to_login: "Return",
			error_sso: "Your store is not part of this program or you dont have permission to access the app. Please contact your leadership, or contact your Customer Success Specialist."
		},
		otp: {
			required_field: "This field is required",
			user_field: "User or email",
			code_field: "Code",
			confirm_btn: "Confirm code",
		},
		validate_recovery: {
			required_field: "This field is required",
			user_field: "User or email",
			password: "Password",
			confirm_password: "Confirm Password",
			password_confirm_error: "Passwords do not match",
			confirm_btn: "Change Password",
			'requirement_1': 'Minimum 10 characters - maximum 40',
      'requirement_2': 'Must count at least one uppercase letter',
      'requirement_3': 'Must have at least one number',
      'msj_pass_change_success' : 'The password change was successful',
      'min_len_character' : 'The password must have a minimum of 10 characters',
      'max_len_character' : 'he password must have a maximum of 40 characters',
      'blank_spaces' : 'The password must not have blank spaces.',
      'password_lowercase_min' : 'The password must have a minimum of 1 lowercase characters.',
      'password_uppercase_min' : 'The password must have a minimum of 1 upercase characters.',
      'password_digits_min' : 'The password must have a minimum of 1 digit characters',
      'password_special_min' : 'The password must have a minimun of 1 special characters',
      'password_strength' : 'Must be a strong password',
      'error_recovery_pass' : 'The password change was not made, try again'
		},
		logout: {
			title: "You are about to leave",
			subtitle: "Are you sure you want to leave?",
			confirm: "Yes",
			cancel: "No"
		},
		workspaces: {
			required_field: "This field is required",
			btn_workspaces: "Connected",
			workspace_field: "workspace"
		}
	},
};
