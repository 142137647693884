import axios from "axios";
import jsonLocaleTranslate from "../JSON/translations.json";

const HttpClient = axios.create();

import ApiResources from "../ApiResources";

const setLanguageNavigator = () => {
  const language_navigator = navigator.language.split("-")[0].toUpperCase();
  switch (language_navigator) {
    case "EN":
      return { id: language_navigator, name: "English" };
      break;
    case "ES":
      return { id: language_navigator, name: "Español" };
      break;
    default:
      return { id: "EN", name: "English" };
      break;
  }
};
const loadInitialData = () => {
  if (localStorage.getItem("language") == null)
    localStorage.setItem("language", JSON.stringify(setLanguageNavigator()));

  if (localStorage.getItem("translations") == null)
    localStorage.setItem("translations", JSON.stringify(jsonLocaleTranslate));
};

const ProviderAuth = () => {
  const { REACT_APP_CONTEXT: contextApp } = process.env;
  return {
    login: async (data) => {
      loadInitialData();
      let _data = {
        email: data.email,
        password: data.password,
      };
      const login = await HttpClient.post(ApiResources.login, _data);
      if (login.data.change_password) {
        window.location.assign(`${contextApp}/reset_password?email=${data.email}&token=${login.data.token}`)
        return {
          data : {
            token : "",
            error: "change_password"
          }
        };
      }
      else {
        login.data.auth["terms_valid"] = false;
        if (login.data.auth.terms_accepted == 1) {
          const terms = await HttpClient.get(
            `${ApiResources.verify_terms}?country=${login.data.auth.country_id}&version=${login.data.auth.terms_version}&jwt=${login.data.auth.token}`
          );
          login.data.auth["terms_valid"] = terms.status == 200;
        }
        return login;
      }
    },
    reset_password: async (data) => {
      return await HttpClient.post(ApiResources.reset_password, data);
    },
    check_otp: async (data) => {
      let _data = {
        token: data.token,
        code: data.code,
      };
      return await HttpClient.post(ApiResources.confirm_reset, _data);
    },
    change_password: async (data) => {
      let _data = {
        token: data.token,
        new_password: data.password,
      };
      return await HttpClient.post(ApiResources.change_password, _data);
    },
    logout: async (localStorage) => {
      if (localStorage.getItem("url_sso") != null) {
        const url = localStorage.getItem("url_sso").replace("sso", "slo");
        window.location.assign(`${url}&return_to=${contextApp}/login`)
      }
    },
    loginSSO: async (searchParams) => {
      loadInitialData();
      const dataSSOLogin = {
        'token_push': "tokenPush",
        'language': "en",
      };
      HttpClient.defaults.headers.common['Authorization'] = searchParams.get("token");
      const login = await HttpClient.post(`/me/mobile_config`, dataSSOLogin)

      login.data.auth["terms_valid"] = false;
      if (login.data.auth.terms_accepted == 1) {
        const terms = await HttpClient.get(
          `${ApiResources.verify_terms}?country=${login.data.auth.country_id}&version=${login.data.auth.terms_version}&jwt=${login.data.auth.token}`
        );
        login.data.auth["terms_valid"] = terms.status == 200;
      }
      const response_login = {
        ...login,
        data: {
          ...login.data,
          auth: {
            ...login.data.auth,
            token: searchParams.get("token")
          }
        }
      };
      return response_login;
    },
    loadAuthMethod: async (data) => {
      const res = await HttpClient.get(`/api/v4/workspaces/${data.workspaces}/auth-method`);
      const url = res['data'].url;
      localStorage.setItem("url_sso", url)
      window.location.assign(`${url}&return_to=${contextApp}/login`)
    }
  };
};

export default ProviderAuth;
