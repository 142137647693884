import { useState, useContext } from "react";
import LayoutContainer from "./LayoutContainer";
import { makeStyles } from "@material-ui/core/styles";
import AltoTheme from "../../../Themes";
import {
  AppConsumer,
  AppContext,
  AppProvider,
} from "../../../providers/AppProvider";
import DataSync from "../../DataSync";
import { BrowserRouter as Router } from "react-router-dom";
import { Status } from "../../Generals/components/Status";
import { StatusMessage } from "../../Generals/components/StatusMessage";
import Accounts from "../../Accounts";
import TermsAndConditions from "../../TermsAndConditions";
import Alert from "../../Generals/components/Alert";
import PushNotifications from "../../PushNotifications";

const useStyles = makeStyles({
  imageContainer: {
    paddingBottom: "2rem",
  },
  imageLogin: { height: "2.5rem" },
});

const { REACT_APP_CONTEXT: contextApp } = process.env;

const RenderConfigApp = () => {
  const [viewAccounts, setViewAccounts] = useState(true);
  const { setApp } = useContext(AppContext);

  const classes = useStyles();
  return (
    <AppConsumer>
      {({ sync, auth, accounts, translate }) => {
        ///Validacion  aprobacion terminos y condiciones
        if (auth != null && !auth.terms_valid) return <TermsAndConditions />;
        /// Validacion cuentas existentes
        if (!accounts?.length && accounts != null)
          return (
            <Alert
              open={() => {}}
              onClose={() => {
                if (localStorage.getItem("url_sso") != null) {
                  const url = localStorage
                    .getItem("url_sso")
                    .replace("sso", "slo");
                  window.location.assign(
                    `${url}&return_to=${contextApp}/login`
                  );
                  setApp("END_SESSION", {});
                  return;
                }
                setApp("END_SESSION", {});
                window.location.assign(`${contextApp}/login`);
              }}
              text={`${translate("accounts", "lbl_no_assing_account")}`}
              justifyItems="flex-start"
              imageComponent={
                <div className={classes.imageContainer}>
                  <img
                    src={`${contextApp}/static/alliance.svg`}
                    alt="logoAlliance"
                    className={classes.imageLogin}
                  />
                </div>
              }
            />
          );
        return (
          <>
            <Router>
              {!sync && (
                <Accounts
                  openDialog={viewAccounts}
                  handleStatusDialogAccount={() => setViewAccounts(false)}
                  disableBackdropClick
                />
              )}
              <DataSync />
              {sync && <LayoutContainer />}
            </Router>
          </>
        );
      }}
    </AppConsumer>
  );
};

export default () => {

  return (
      <AppProvider>
        <PushNotifications />
        <RenderConfigApp />
        <Status />
        <StatusMessage />
      </AppProvider>
  );
};
