import { useContext, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { AppContext } from "../../../providers/AppProvider";
import { Snackbar } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const { REACT_APP_CONTEXT: contextApp } = process.env;
export const Status = () => {
  const { translate } = useContext(AppContext);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorData({ error: false, status: null });
    // handleReset();
  };
  const status_messages = {
    400: "C400",
    401: "C401",
    403: "C403",
    404: "C404",
    405: "C405",
    409: "C999",
    500: "C500",
    502: "C502",
    503: "C503",
  };
  const [errorData, setErrorData] = useState({ error: false, status: null, config : null});

  const { httpClient } = useContext(AppContext);
  // status_api != null? true: false
  httpClient.interceptors.response.use(
    (respSucces) => {
      return respSucces;
    },
    (respError) => {
      if (respError.__CANCEL__) return;
      if (respError.response?.status) {
        setErrorData({ error: true, status: respError.response.status, config : respError.config });
        if (respError.response.status == 401) {
          localStorage.clear();
          window.location.assign(`${contextApp}`);
        }
      } else {
        setErrorData({ error: true, status: null, config : null });
      }
      return Promise.reject(respError);
    }
  );

  return errorData.error ? (
    <Snackbar
      open={errorData.error}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity="error">
        <div>{`Status : ${errorData.status}`}</div>
        <div>{`Message : ${translate("status_code", status_messages[errorData.status])}`}</div>
        <div>{`Resource : ${errorData?.config?.url}`}</div>
      </Alert>
    </Snackbar>
  ) : (
    <></>
  );
};
