import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { useContext } from "react";
import { AppContext } from "../../../providers/AppProvider";

const useStyles = makeStyles({
  noDataLabel: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
});

export default ({ text = null }) => {
  const { translate } = useContext(AppContext);
  const classes = useStyles();
  text = text ? text : translate("generals", "no_items_to_show");
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid item xs>
        <ErrorOutline style={{ fontSize: "2.5rem" }} color="secondary" />
      </Grid>
      <Grid item xs>
        <span className={classes.noDataLabel}>{text}</span>
      </Grid>
    </Grid>
  );
};