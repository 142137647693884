import { Button, Container, Dialog, Grid, Typography } from "@material-ui/core";
import { useContext, useState } from "react";
import { AppContext } from "../../providers/AppProvider";
import ScreenLoader from "../Generals/components/ScreenLoader";
import ReactHtmlParser from "react-html-parser";
import "./style.css";
import ApiResources from "../../ApiResources";

const { REACT_APP_CONTEXT: contextApp } = process.env;

export default () => {
  const { auth, translate, postTerm, httpClient, token } =
    useContext(AppContext);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");
  const [version, setVersion] = useState("");

  const handleClose = () => {
    setLoading(true);
    postTerm(version);
    setOpen(false);
  };

  const getText = async () => {
    const resp = await httpClient.get(
      `${ApiResources.terminos}?country=${auth.country_id}&jwt=${token}`
    );
    setVersion(resp.data.version);
    return resp.data.sections;
  };

  const loadText = async () => {
    setText(await getText());
    setLoading(false);
  };

  if (loading) {
    loadText();
  }

  const Text = () => {
    return <>{ReactHtmlParser(text)}</>;
  };
  if (!loading)
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen
      >
        <Container maxWidth="md" style={{ marginTop: 25, paddingBottom: 50 }}>
          <Grid container direction="row">
            <Grid item xs={12} md={12}>
              <img
                src={`${contextApp}/static/alliance.svg`}
                alt="logoAlliance"
                style={{ height: "2.5rem" }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="flex-start"
          >
            <Grid item xs={12} md={12}>
              <Typography style={{ fontWeight: "bold", marginTop: "1.563rem" }}>
                {translate("login", "lbl_text_terms")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Text />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                {translate("generals", "btn_continue")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    );
  else return <ScreenLoader />;
};
