import React, { useContext } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { AppContext } from "../../../providers/AppProvider";
import { Button, Snackbar } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const StatusMessage = () => {
  const { messageClient, setApp } = useContext(AppContext);
  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setApp("SET_MESSAGE", {
      severity: null,
      open: false,
      autoHideDuration: null,
      message: null,
      action: null,
    });
  };

  return messageClient.open ? (
    <Snackbar
      open={messageClient.open}
      autoHideDuration={messageClient.autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert 
        onClose={handleClose}
        severity={messageClient.severity}
        action={messageClient.action}
        >
        {messageClient.message}
      </Alert>
    </Snackbar>
  ) : (
    <></>
  );
};
