import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import {
  List,
  ListItem,
  ListItemText,
  TextField,
  InputAdornment,
  Grid,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useState } from "react";
import filter from "lodash/filter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "80%",
    maxHeight: "27.188rem",
  },
  margin: {
    margin: theme.spacing(1),
  },
  divinput: {
    display: "none",
  },
}));

const SelectItemDialog = (props) => {
  const classes = useStyles();
  const {
    source = "item-dialog",
    onSelect,
    onClose,
    textItems,
    open,
    label,
    listItems,
    display_search = false,
    filter_term = "",
  } = props;

  const onSelectItem = (item) => onSelect(item);
  const onCloseSelect = () => onClose();
  const [filterItems, setFilterItems] = useState(listItems);

  const handleChangeSearch = (event) => {
    if (event.target.value != "" && event.target.value != null) {
      let newList = filter(listItems, (el) =>
        el[filter_term].toLowerCase().includes(event.target.value.toLowerCase())
      );
      setFilterItems(newList);
    } else setFilterItems(listItems);
  };

  return (
    <Dialog
      maxWidth="xs"
      onClose={onCloseSelect}
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
        >
          <Grid item xs={11} md={11}>
            {label}
          </Grid>
          <Grid item xs={1} md={1}>
            <IconButton onClick={onClose} color="primary">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {display_search && (
        <TextField
          className={classes.margin}
          id="input-with-icon-textfield"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleChangeSearch}
        />
      )}
      <DialogContent dividers id={`confirm-dialog-content-${source}`}>
        <List>
          {filterItems.map((option, index) => (
            <ListItem button key={index} onClick={() => onSelectItem(option)}>
              <ListItemText primary={textItems(option)} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default SelectItemDialog;
