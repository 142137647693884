import { createContext, useReducer } from "react";
import remove from "lodash/remove";
import ApiResources from "../../ApiResources";
import PouchDB from "pouchdb";
const ProcessesBoxContext = createContext();

const db = new PouchDB("alliance_proccess");

const { Provider, Consumer } = ProcessesBoxContext;

const initialState = {
  processes: [],
  process: null,
  isOpen: false,
};

const reducer = (state, action) => ({
  ...state,
  ...action.data,
});

function ProcessesBoxProvider({
  children,
  httpClient,
  translate = () => ({}),
}) {
  const [state, setProcess] = useReducer(reducer, initialState);

  const getProcesses = async () => {
    const processdb = await db.allDocs({
      include_docs: true,
      descending: true,
    });
    const processes = processdb.rows.map((doc) => doc.doc);
    setProcess({
      type: "ADD_PROCESSES",
      data: { processes, isOpen: processes.length > 0 },
    });
  };

  const addProcess = async (data) => {
    const id = crypto.randomUUID();
    const dateTime = new Date().toISOString()
    let process = { ...data, _id: id, datetime: dateTime};
    let response = await httpClient.post(ApiResources.signal_list, data?.data)
    process = {...process, signal: response?.data}

    const processes = [...state.processes, process];

    db.put(process).then(() => {
      setProcess({
        type: "ADD_PROCESSES",
        data: { processes: processes, isOpen: true },
      });
    });
  };

  const updateProcess = async (item) => {
    const process = state.processes.find((el) => el._id==item._id)

    let signal_name = process?.data?.signal;
    let response = await httpClient.get(ApiResources.signal_item.replace(":signal_id", process?.signal?.id))

    let signal_process = response["data"]["processes"][signal_name]
    if (signal_process?.status.toLowerCase() == "error"){
      console.log("Error geting signal data")

      return signal_process
    }
    if (signal_process){
      console.log("update")
      try{
        const doc = await db.get(process["_id"]);
        await db.put({...doc, ...signal_process?.output})
        return signal_process["output"]
      }
      catch {
        return {status: "local_not_found"}
      }

    }

    return null
  };

  const closeViewProcesses = () =>
    setProcess({
      type: "SET_OPEN",
      data: { isOpen: false },
    });

  const removeProcess = async (item) => {
    console.log("delete")
    try{
      const doc = await db.get(item?._id)
      await db.remove(doc)
    }
    catch(error){
      console.log(error)
    }
    finally{
      let array_procceses = state.processes;
      remove(array_procceses, (process) => process._id == item._id);
      setProcess({
        type: "ADD_PROCESSES",
        data: { processes: array_procceses, isOpen: true },
      });
    }
  };

  const deleteBdProcesses = async () => {
    await db.destroy()
      .then(() => console.log("Database deleted"))
      .catch((err) => console.log(err));
  };
  const openProccess = () => {
    setProcess({
      data: { isOpen: true },
    });
  };
  const value = {
    ...state,
    httpClient,
    translate,
    closeViewProcesses,
    getProcesses,
    addProcess,
    updateProcess,
    removeProcess,
    deleteBdProcesses,
    openProccess,
  };

  return <Provider value={value} children={children} />;
}

export {
  Consumer as ProcessesBoxConsumer,
  ProcessesBoxContext,
  ProcessesBoxProvider,
};
