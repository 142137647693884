import {
  ListItemText,
  ListItemIcon,
  ListItem,
  CircularProgress,
  IconButton,
  ListItemSecondaryAction,
  Grid,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useEffect, useState, useRef } from "react";

export default ({ item, index, handledDeleteProcess, updateProcess }) => {
  // TODO: setear timer/proceso que actualice el item (updateProcess)
  const StatusIcon = ({ status, progress }) => {
    // error, processing, done
    switch (status) {
      case "done":
        return <DoneIcon style={{ color: "green" }} />;
      case "processing":
        return <CircularProgress
          size={18} color="secondary"
          variant={progress == 0 ? "indeterminate" : "determinate"}
          value={progress}
          thickness="7.5" />;
      case "error":
        return <ErrorIcon color="error" />;
      default:
        return <></>;
    }
  };

  const [status, setStatus] = useState("processing")
  const [progress, setProgress] = useState(0)
  const clickAble = status == "done";
  const [result, setResult] = useState(null);

  let timer;

  const fetchProcess = async () => {
    let result = await updateProcess(item)
    if (!result) return

    if (result?.status?.toLowerCase() == "error") {
      clearInterval(timer)
      setStatus("error")
      return
    } else if (result?.status?.toLowerCase() == "local_not_found") {
      await handledDeleteProcess(item);
      return
    }

    setProgress(result.percentage)
    if (result.file_url) {
      setStatus("done");
      setResult(result);
      return;
    }
  }

  const download = async () => {
    let returnUrl = window.location.protocol + "//" + window.location.host
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = returnUrl + result.file_url;
    a.download = item.label;
    a.click();
    await handledDeleteProcess(item);
  }

  useEffect(() => {
    timer = setInterval(fetchProcess, 3000);

    return () => clearInterval(timer);
  });
  return (
    <ListItem
      key={item.id}
      divider
    >
      <ListItemIcon children={<StatusIcon status={status} progress={progress} />} />
      <ListItemText
        primary={`${item.label}_${item.datetime}.xlsx`}
        style={{ marginRight: 30 }}
      />
      <ListItemSecondaryAction>
        <Grid container spacing={0}>
          <Grid item style={{ marginLeft: "1.5rem" }}>
            <IconButton
              color="secondary"
              edge="end"
              style={{ color: "red" }}
              aria-label="delete"
              onClick={() => {
                handledDeleteProcess(item)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              edge="end"
              aria-label="download"
              color={clickAble ? "secondary" : ""}
              onClick={clickAble ? () => download() : null}
            >
              <GetAppIcon />
            </IconButton>
          </Grid>
        </Grid>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
