import { useReducer, createContext } from 'react';
import axios from 'axios';
import get from 'lodash/get';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ApiResources from '../ApiResources';
import spread from 'lodash/spread';
import union from 'lodash/union';
import replace from 'lodash/replace';

const AppContext = createContext();
const { Provider, Consumer } = AppContext;

const initialState = {
  account: JSON.parse(localStorage.getItem('current_account')),
  accounts: JSON.parse(localStorage.getItem('account')),
  places: JSON.parse(localStorage.getItem('places')),
  countries: JSON.parse(localStorage.getItem('countries')),
  app_configuration: JSON.parse(localStorage.getItem('app_configuration')),
  translations: JSON.parse(localStorage.getItem('translations')),
  viewer_config: JSON.parse(localStorage.getItem('viewer_config')),
  language: JSON.parse(localStorage.getItem('language')),
  auth: JSON.parse(localStorage.getItem('auth')),
  alliance_user: JSON.parse(localStorage.getItem('alliance_user')),
  selected_module: localStorage.getItem('selected_module'),
  current_module: JSON.parse(localStorage.getItem('current_module')),
  axios: axios,
  sync: localStorage.getItem('sync'),
  token: localStorage.getItem('auth_token'),
  token_push: localStorage.getItem('token_push'),
  alliance_home: localStorage.getItem('alliance_home'),
  home_modules: JSON.parse(localStorage.getItem('home_modules')),
  web_routes: JSON.parse(localStorage.getItem('web_routes')),
  httpClient: axios.create(),
  display_menu: true,
  messageClient: {
    severity: null,
    open: false,
    autoHideDuration: null,
    message: null,
  },
  accountConfiguration: null,
  accountTranslations: null,
  nonLocal: null,
  folders_config: JSON.parse(localStorage.getItem('folders_config')),
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACCOUNT':
      localStorage.setItem('current_account', JSON.stringify(action.data));
      return {
        ...state,
        sync: false,
        account: action.data,
      };
    case 'SET_TOKEN_PUSH':
      localStorage.setItem('token_push', JSON.stringify(action.data));
      return {
        ...state,
        token_push: action.data,
      };
    case 'SET_PLACES':
      localStorage.setItem('places', JSON.stringify(action.data));
      return {
        ...state,
        places: action.data,
      };
    case 'SET_COUNTRIES':
      localStorage.setItem('countries', JSON.stringify(action.data));
      return {
        ...state,
        countries: action.data,
      };
    case 'SET_APP_CONFIGURATION':
      let _app_configuration = { ...action.data };
      localStorage.setItem(
        'app_configuration',
        JSON.stringify(_app_configuration)
      );
      return {
        ...state,
        app_configuration: _app_configuration,
      };
    case 'SET_TRANSLATIONS':
      localStorage.setItem('translations', JSON.stringify(action.data));
      return {
        ...state,
        translations: action.data,
      };
    case 'SET_VIEWER_CONFIG':
      localStorage.setItem('viewer_config', JSON.stringify(action.data));
      return {
        ...state,
        viewer_config: action.data,
      };
    case 'SET_SYNC':
      localStorage.setItem('sync', JSON.stringify(action.data));
      localStorage.setItem('selected_module', 'home');
      localStorage.setItem('alliance_home', 1);
      return {
        ...state,
        sync: action.data,
        alliance_home: 1,
        selected_module: 'home',
      };
    case 'SET_ALLIANCE_HOME':
      localStorage.setItem('alliance_home', JSON.stringify(action.data));
      return {
        ...state,
        alliance_home: action.data,
      };
    case 'SET_HOME_MODULES':
      localStorage.setItem('home_modules', JSON.stringify(action.data));
      const fn = spread(union);
      const modulesbuilder = (item) => item.web_routes;

      const web_routes = fn([
        ...action.data.home.map(modulesbuilder),
        ...action.data.backoffice.map(modulesbuilder),
      ]);
      const new_web_routes = [
        ...web_routes,
        '/customer_books/list',
        '/customer_books/:bookID?/records',
      ];
      localStorage.setItem('web_routes', JSON.stringify(new_web_routes));
      return {
        ...state,
        home_modules: action.data,
        web_routes: new_web_routes,
      };
    case 'SET_CURRENT_MODULE':
      localStorage.setItem('current_module', JSON.stringify(action.data));
      return {
        ...state,
        current_module: action.data,
      };
    case 'SET_LANGUAGE':
      return {
        ...state,
        language: action.data,
      };
    case 'SET_ALLIANCE_USER':
      localStorage.setItem('alliance_user', JSON.stringify(action.data));
      return {
        ...state,
        alliance_user: action.data,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        messageClient: action.data,
      };
    case 'SET_TERMS':
      const _state = {
        ...state,
        auth: { ...state.auth, terms_valid: action.data },
      };
      localStorage.setItem('auth', JSON.stringify(_state.auth));
      return _state;
    case 'SET_SELECTED_MODULE':
      localStorage.setItem('selected_module', action.data);
      return { ...state, selected_module: action.data };
    case 'END_SESSION':
      localStorage.clear();
      return {
        ...initialState,
      };
    case 'SET_ACCOUNT_CONFIGURATION':
      return {
        ...state,
        accountConfiguration: { ...action.data },
      };
    case 'SET_ACCOUNT_TRANSLATIONS':
      return {
        ...state,
        accountTranslations: action.data,
      };
    case 'SET_NON_LOCAL':
      return {
        ...state,
        nonLocal: action.data,
      };
    case 'DISPLAY_MENU':
      return {
        ...state,
        display_menu: action.data,
      };

    case 'SET_CONFIGURATIONS':
      return {
        ...state,
        accountTranslations: action.data.translations,
        accountConfiguration: action.data.configurations,
      };
    case 'SET_FOLDERS_CONFIG':
      localStorage.setItem('folders_config', JSON.stringify(action.data));
      return { ...state, folders_config: action.data };
    default:
      break;
  }
};

function AppProvider({ children }) {
  const [state, setReducerApp] = useReducer(reducer, initialState);

  const getKeyTranslate = (module, key) =>
    get(
      !state.nonLocal ? state.translations : state.accountTranslations,
      `${state.language?.id}.${module}.${key}`,
      `${state.language?.id}._${module}_${key}_not_found`
    );

  const postTerm = async (version) => {
    state.httpClient.defaults.headers.common['Authorization'] = state.token;
    let body = new FormData();
    body.append('terms_version', version);
    await state.httpClient.post(`${ApiResources.save_terms}`, body);
    setReducerApp({ type: 'SET_TERMS', data: true });
  };

  function getCategoryIcon(category) {
    if (category == 'R013') return 'icon-lawyer-list.svg';
    const {
      app_configuration: {
        event_clasification: { list },
      },
    } = state;
    const { icon = null } = list.find((item) => item.key == category) ?? {};
    return icon ? icon : 'ico-round-unknown.svg';
  }

  const find = (array = [], key) => {
    for (const item of array) {
      const result = item.key === key ? item : find(item.children, key);
      if (result) return result;
    }
  };

  function getCategoryTag(subcategory) {
    const {
      app_configuration: {
        event_clasification: { list },
      },
    } = state;

    const category = find(list, subcategory);
    return category ? category : { name: '' };
  }

  const getFoldersConfig = async (country) => {
    state.httpClient.defaults.headers.common['Authorization'] = state.token;
    const res = await state.httpClient.get(
      ApiResources.folders_config.replace(':country', country)
    );
    setReducerApp({ type: 'SET_FOLDERS_CONFIG', data: res['data'] });
    return res['data'];
  };

  async function getAccountConfigs(accountId) {
    state.httpClient.defaults.headers.common['Authorization'] = state.token;
    const [translations, configurations] = await Promise.all([
      state.httpClient.get(
        ApiResources.app_translations.replace(':id', accountId)
      ),
      state.httpClient.get(
        ApiResources.app_configuration.replace(':id', accountId)
      ),
    ]);
    const configs = {
      configurations: configurations.data,
      translations: translations.data,
    };
    setReducerApp({
      type: 'SET_CONFIGURATIONS',
      data: configs,
    });

    return configs;
  }
  const show_menu = (display) =>
    setReducerApp({ type: 'DISPLAY_MENU', data: display });

  const getHttpClient = (skip_interceptor = false) => {
    const { httpClient, token } = state;
    const client = skip_interceptor ? axios.create() : httpClient;
    client.defaults.headers.common["Authorization"] = token;
    return client;
  };

  const changeLang = (lang) => {
    localStorage.setItem('language', JSON.stringify(lang));
    setReducerApp({ type: 'SET_LANGUAGE', data: lang });
  };

  const hashParams = (param = null, action = null, value = null) => {
    var hash = window.location.hash.replace('#', '/');
    var newUrl = new URL(replace(hash, '//', '/'), window.location.origin);
    var paramsHashModule = new URLSearchParams(
      newUrl.search ? newUrl.search : ''
    );
    if (action == 'set') paramsHashModule.set(param, value);
    if (action == 'delete') paramsHashModule.delete(param);
    paramsHashModule.delete('rewrite');
    paramsHashModule.append('rewrite', Math.random());
    return `${newUrl.pathname}?${paramsHashModule.toString()}`
      .replace('/', '')
      .toString();
  };

  const getCurrentconfig = (nonLocal) =>
    nonLocal ? state.accountConfiguration : state.app_configuration;

  const value = {
    ...state,
    translate: getKeyTranslate,
    isMobile: useMediaQuery(useTheme().breakpoints.between('xs', 'sm')),
    dateTimeFormat: 'MMMM dd, Y HH:mm',
    dateFormat: 'MMMM dd, Y',
    changeLang,
    postTerm,
    getCategoryIcon,
    getCategoryTag,
    getAccountConfigs,
    show_menu,
    getFoldersConfig,
    getHttpClient,
    hashParams,
    getCurrentconfig,
    setApp: (type, data) => {
      setReducerApp({ type: type, data });
    },
  };

  return <Provider value={value}>{children}</Provider>;
}

export { Consumer as AppConsumer, AppContext, AppProvider };
