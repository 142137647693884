import { Auth, AuthView } from 'auth-lib';
import AltoTheme from './Themes';
import ProviderAuth from './providers/ProviderAuth';
import Layout from './modules/Layout/components/Layout';
import CssBaseline from '@material-ui/core/CssBaseline';
import CustomTranslations from './providers/CustomTranslations';
import { createMuiTheme } from '@material-ui/core';

const { REACT_APP_CONTEXT: contextApp } = process.env;

export default () => {
  const defaultTheme = createMuiTheme({
    ...AltoTheme,
    spacing: (factor) => `${0.5 * factor}rem`,
  });

  return (
    <Auth
      authentication_method={{
        sso: true,
        form: true,
      }}
      providerClient={ProviderAuth()}
      background={`${contextApp}/static/welcomelogin.jpg`}
      theme={defaultTheme}
      home_url={`${contextApp}/workspace`}
      logo={`${contextApp}/static/alliance.svg`}
      translations={CustomTranslations}
    >
      <AuthView path={`${contextApp}`}>
        <CssBaseline />
        <Layout />
      </AuthView>
    </Auth>
  );
};
