import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyDfMnCiF7TLdg3vr79hMDDqEiIilZlD9EQ",
  authDomain: "alliance-beta.firebaseapp.com",
  databaseURL: "https://alliance-beta.firebaseio.com",
  projectId: "alliance-beta",
  storageBucket: "alliance-beta.appspot.com",
  messagingSenderId: "636282339663",
  appId: "1:636282339663:web:f40de2100815cec938053a",
  measurementId: "G-PNJYZG0SPD",
};

initializeApp(firebaseConfig);
const messaging = getMessaging();
const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getBrowserToken = async (setTokenFound) => {
  let currentToken = "";
  try {
    currentToken = await getToken(messaging, { vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token.", error);
  }
  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
