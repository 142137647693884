import { useContext } from "react";
import ApiResources from "../../ApiResources";
import { AppContext } from "../../providers/AppProvider";
import SelectItemDialog from "../Generals/components/SelectItemDialog";

export default ({ openDialog, handleStatusDialogTranslate }) => {
  const { translate, changeLang, alliance_user, getHttpClient } =
    useContext(AppContext);

  const onSelect = async (language) => {
    handleStatusDialogTranslate(!openDialog);
    changeLang(language);
    const client = getHttpClient();
    await client.patch(ApiResources.people.replace(":id", alliance_user.id), {
      language: language.id.toLocaleLowerCase(),
    });
    window.location.reload();
  };
  const onClose = () => handleStatusDialogTranslate(!openDialog);

  return (
    <SelectItemDialog
      open={openDialog}
      onClose={onClose}
      onSelect={onSelect}
      label={translate("generals", "change_language")}
      listItems={[
        { id: "EN", name: "English" },
        { id: "ES", name: "Español" },
      ]}
      textItems={(item) => item.name}
    />
  );
};
