import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  makeStyles,
} from "@material-ui/core";
import { useContext } from "react";
import { AppContext } from "../../../providers/AppProvider";

const useStyles = makeStyles((theme) => ({
  alertContent: (props)=>({
    display: "grid",
    justifyItems: props.justifyItems,
    textAlign: "center",
  }),
  alertText: {
    fontSize: "0.875rem",
    color: "#202020",
  },
  alertIcon: {
    margin: "1.5rem auto",
    fontSize: "2.813rem",
    color: theme.palette.secondary.main,
  },
}));

export default ({ open, text, imageComponent, onClose, justifyItems="center" }) => {
  const { translate } = useContext(AppContext);
  const classes = useStyles({justifyItems})
  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogContent className={classes.alertContent}>
        {imageComponent}
        <span id="alert-dialog-description" className={classes.alertText}>
          {text}
        </span>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="secondary"
          style={{ fontWeight: "bold" }}
        >
          {translate("generals", "lbl_ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
