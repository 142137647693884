import { Container, Grid, AppBar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

export default () => {
  const useStyle = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    appBar: {
      backgroundColor: "#FFFFFF",
      position: "relative",
    },
  }));

  const classes = useStyle();

  return (
    <>
      <AppBar className={classes.appBar} elevation={1}>
        <Container maxWidth="lg">
          <Grid
            spacing={3}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            {[...Array(2)].map((i, index) => (
              <Grid item xs={12} md={12} key={index}>
                <Skeleton style={{ width: "100%", height: "1.25rem" }} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </AppBar>
      <Container maxWidth="md">
        <Grid
          spacing={3}
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
        >
          {[...Array(9)].map((i, index) => (
            // ????????
            <Grid item xs={12} md={12} key={index}>
              <Skeleton style={{ width: "100%", height: "1.875rem" }} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};
