const AltoTheme = {
  palette: {
    primary: {
      light: "#202020",
      main: "#202020",
      dark: "#202020",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#DAE3EA",
      main: "#013d6d",
      dark: "#013d6d",
      contrastText: "#ffffff",
    },
  },
  typography:{
    fontFamily:[
      "sans-serif!important"
    ]
  }
};
export default AltoTheme;
