import { useContext, cloneElement, Suspense, lazy } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { AppContext } from './providers/AppProvider';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

const ScreenLoader = lazy(() =>
  import('./modules/Generals/components/ScreenLoader')
);
const LogoutApp = lazy(() => import('./modules/LogoutApp'));
const RoutePermissions = lazy(() => import('./modules/RoutePermissions'));

import dictionaryRoutes from './RoutingComponents';
import dictionaryRedirectRoutes from './RoutingRedirectsComponents';
import BackDropLoader from './modules/Generals/components/BackDropLoader';

const { REACT_APP_CONTEXT: contextApp } = process.env;

export default function AppRouting({ setRouteSelected }) {
  const { sync, web_routes, translate, home_modules } = useContext(AppContext);
  const location = useLocation();

  // se agregan rutas por defecto que no pueden faltar
  const local_routes = [...web_routes, '/workspace', '/home', '/'];

  const pathBuilder = (path) => `${contextApp}${path}`;
  const ProtectedRouted = () => {
    let location = useLocation();
    const exist_route = find(dictionaryRoutes, [
      'route',
      location.pathname.replace(contextApp, ''),
    ]);
    let messageRoute = translate('status_code', 'C404');
    if (exist_route) {
      messageRoute = translate('status_code', 'C403');
    }
    return (
      <RoutePermissions message={`${messageRoute} : ${location.pathname}`} />
    );
  };

  if (sync == false || sync == undefined || sync == null)
    return <ScreenLoader />;
  return (
    <Suspense fallback={<BackDropLoader open />}>
      <Switch>
        <Route exact key={`logout_app`} path={pathBuilder('/end_session')}>
          <LogoutApp />
        </Route>
        {local_routes.map((route, index) => {
          let dictionaryRoute = find(dictionaryRoutes, ['route', route]);
          return (
            <Route
              exact
              key={index}
              path={pathBuilder(route)}
              render={() => {
                const array = [
                  ...home_modules.backoffice,
                  ...home_modules.home,
                ];
                const index = findIndex(array, function (o) {
                  return o.web_routes.includes(route);
                });
                const item = array[index];
                setRouteSelected(item);
                return cloneElement(
                  dictionaryRoute ? (
                    dictionaryRoute.component
                  ) : (
                    <ProtectedRouted />
                  ),
                  {
                    initial_path: dictionaryRoute.initial_path,
                  }
                );
              }}
            />
          );
        })}
        {dictionaryRedirectRoutes.map((dictionaryRedirectRoute, index) => {
          let params_route = new URLSearchParams(location.search);

          if (dictionaryRedirectRoute.search) {
            new URLSearchParams(dictionaryRedirectRoute.search).forEach(
              (value, key) => {
                params_route.append(key, value);
              }
            );
          }

          return (
            <Redirect
              exact
              key={index}
              from={dictionaryRedirectRoute.from}
              to={{
                pathname: dictionaryRedirectRoute.to,
                search: `${params_route.toString().replace('?', '')}`,
              }}
            />
          );
        })}

        <Route path="*" key={`path_default`}>
          <ProtectedRouted />
        </Route>
      </Switch>
    </Suspense>
  );
}
